var messengerChatTYPE = {
  messenger_group_url: '',
};

const MessengerChatReducer = (state = messengerChatTYPE, action) => {
  switch (action.type) {
    case 'messengerChatTYPE':
      delete action.type;
      return {
        ...state,
        messenger_group_url: action.payload.messenger_group_url,
      };
    default:
      return state;
  }
};
export default MessengerChatReducer;
