import axios from 'axios';
import moment from 'moment';
/**
 *
 * @returns Display TimeZone
 */
export function dateTimeZone() {
  const date = new Date();
  const dateAsString = date.toString();
  // eslint-disable-next-line no-useless-escape
  const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
  const matches = timezone.match(/\b(\w)/g);
  const Abbreviations = matches.join('');
  return Abbreviations;
}

export function s3ImageUpload(file) {
  let newFileName = 'Profile/' + +moment() + '_' + file.name;
  const { REACT_APP_AWS_STORAGE_BUCKET_NAME } = process.env;

  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `https://${REACT_APP_AWS_STORAGE_BUCKET_NAME}.s3.amazonaws.com/Profile/${newFileName}`,
    headers: {
      'Content-Type': 'image/png',
    },
    data: file,
  };

  return axios.request(config).then(() => config.url);
}
