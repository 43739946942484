var auditHistoryType = {
  isVisible: false,
  slug: '',
};

const auditHistoryTypeReducer = (state = auditHistoryType, action) => {
  switch (action.type) {
    case 'AUDITHISTORY':
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};
export default auditHistoryTypeReducer;
