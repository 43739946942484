import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import { Alert } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { localSearch } from '../../../redux/actions/localSearchAction';
import { dateTimeZone } from '../../../shared_components/pipes/common_functions';
import { useChurnZero } from '../../auth/churnZero';
import useUserTimezone from '../../../hooks/useUserTimezone';

function CommonGlobalAlert(props) {
  const { moment: customMoment, CURRENT_TIMEZONE } = useUserTimezone();
  const dispatchCall = useDispatch();
  const ClaimTaskArray = ['before_seven_day'];
  const churnZeroData = useChurnZero();
  useEffect(() => {
    // track event using churnZero
    try {
      if (churnZeroData.churnInfo) {
        churnZeroData.churnInfo.trackEvent(`Dismissed Alert`, `User dismissed the alert`);
      }
    } catch (err) {
      console.log(err);
    }
  }, [churnZeroData.churnInfo]);

  return (
    <>
      <Alert
        description={
          <>
            <div className="nrow-block">
              <div className="flex flex-start">
                <span className="bg-ico">
                  <i className="fas fa-info-circle"></i>
                </span>
                <div className="ndetail">
                  <div className="nmsg flex">
                    {/* {props.notificationType === 'task' ? (
                      <p className="wrap-text">
                        <div dangerouslySetInnerHTML={{ __html: props.currentData.notification }} />
                      </p>
                    ) : (
                      <p className="wrap-text">{props.currentData.notification}</p>
                    )} */}
                    <p className="wrap-text">
                      <div dangerouslySetInnerHTML={{ __html: props.currentData.notification }} />
                    </p>
                  </div>

                  {props.currentData.notification_from === 'budget' ? (
                    <u>
                      <Link
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        onClick={() => props.parentOnCloseDrawer()}
                        to={`/claim/${props.currentData.claim}/numbers/explore-budget?search=${props?.currentData?.notification_extra_info?.id}`}
                      >
                        View
                      </Link>
                    </u>
                  ) : props.currentData.notification_from === 'settlement_negotiation' ? (
                    <u>
                      <Link
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        onClick={() => props.parentOnCloseDrawer()}
                        to={`/claim/${props.currentData.claim}/numbers/explore-settlement`}
                      >
                        View
                      </Link>
                    </u>
                  ) : props.currentData.notification_from === 'claim_valuation' ? (
                    <u>
                      <Link
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        onClick={() => props.parentOnCloseDrawer()}
                        to={`/claim/${props.currentData.claim}/numbers/explore-claim-valuation?search=${props?.currentData?.notification_extra_info?.id}`}
                      >
                        View
                      </Link>
                    </u>
                  ) : props.currentData.notification_from === 'claim_numbers' ? (
                    <u>
                      <Link
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        onClick={() => props.parentOnCloseDrawer()}
                        to={`/claim/${props.currentData.claim}/numbers`}
                      >
                        View
                      </Link>
                    </u>
                  ) : props.currentData.notification_from === 'liens' ? (
                    <u>
                      <Link
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        onClick={() => props.parentOnCloseDrawer()}
                        to={`/claim/${props.currentData.claim}/numbers/explore-liens`}
                      >
                        View
                      </Link>
                    </u>
                  ) : props.currentData.notification_from === 'claim_dashboard' ||
                    props.currentData.notification_from === 'claim_table_module' ? (
                    <u>
                      <Link
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        onClick={() => props.parentOnCloseDrawer()}
                        to={`/claim/${props.currentData.claim}/overview`}
                      >
                        View
                      </Link>
                    </u>
                  ) : (props.currentData.notification_from === 'task' ||
                      props.currentData.notification_from === 'request') &&
                    ClaimTaskArray?.includes(
                      props?.currentData?.notification_type?.notification_type
                    ) ? (
                    <u>
                      <Link
                        onClick={() => {
                          props.parentOnCloseDrawer();
                        }}
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        to={{
                          pathname: `/claim/${props?.currentData?.claim}/tasks`,
                          search: props.currentData.notification_extra_info?.id
                            ? `?id=${props.currentData.notification_extra_info.id}&law=${props.currentData.notification_extra_info.law}&name=${props.currentData.notification_extra_info.name}&carrier=${props.currentData.notification_extra_info.carrier}`
                            : '',
                        }}
                      >
                        View
                      </Link>
                    </u>
                  ) : (props.currentData.notification_from === 'task' ||
                      props.currentData.notification_from === 'request') &&
                    !ClaimTaskArray?.includes(
                      props?.currentData?.notification_type?.notification_type
                    ) ? (
                    <u>
                      <Link
                        onClick={() => {
                          props.parentOnCloseDrawer();
                        }}
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        to={{
                          pathname: `/global-task`,
                          search: props.currentData.notification_extra_info?.id
                            ? `?id=${props.currentData.notification_extra_info.id}&law=${props.currentData.notification_extra_info.law}&name=${props.currentData.notification_extra_info.name}&carrier=${props.currentData.notification_extra_info.carrier}`
                            : '',
                        }}
                      >
                        View
                      </Link>
                    </u>
                  ) : props.currentData.notification_from === 'documents' ? (
                    <u>
                      {props.currentData.folder_id ? (
                        <Link
                          to={`/claim/${props.currentData.claim}/${props.currentData.notification_from}/${props.currentData.folder_id}?document_id=${props?.currentData?.document_id}`}
                          className="primary-link sm-link"
                          style={{ textAlign: 'left', display: 'block' }}
                          onClick={() => {
                            dispatchCall(
                              localSearch({
                                search_keyword: props.currentData.document_name,
                              })
                            );
                            props.parentOnCloseDrawer();
                          }}
                        >
                          View
                        </Link>
                      ) : (
                        <Link
                          to={`/claim/${props.currentData.claim}/${props.currentData.notification_from}?document_id=${props?.currentData?.document_id}`}
                          className="primary-link sm-link"
                          style={{ textAlign: 'left', display: 'block' }}
                          onClick={() => {
                            dispatchCall(
                              localSearch({
                                search_keyword: props.currentData.document_name,
                              })
                            );
                            props.parentOnCloseDrawer();
                          }}
                        >
                          View
                        </Link>
                      )}
                    </u>
                  ) : props.currentData.notification_from === 'claim_contact' ? (
                    <u>
                      <Link
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        onClick={() => props.parentOnCloseDrawer()}
                        to={`/claim/${props.currentData.claim}/contacts`}
                      >
                        View
                      </Link>
                    </u>
                  ) : props?.currentData?.notification_from === 'event' ? (
                    <u>
                      {props?.currentData?.notification_type?.notification_type !==
                        'delete_event' && (
                        <Link
                          className="primary-link sm-link"
                          style={{ textAlign: 'left', display: 'block' }}
                          onClick={() => props.parentOnCloseDrawer()}
                          to={{
                            pathname: `/events`,
                            search: props.currentData.notification_extra_info?.id
                              ? `?id=${props?.currentData?.notification_extra_info?.id}&s=${moment
                                  .utc(props?.currentData?.notification_extra_info?.start_time)
                                  .format('YYYY-MM-DD')}&e=${moment
                                  .utc(props?.currentData?.notification_extra_info?.start_time)
                                  .add(30, 'days')
                                  .format('YYYY-MM-DD')}`
                              : '',
                          }}
                        >
                          View
                        </Link>
                      )}
                    </u>
                  ) : props.currentData.notification_from === undefined ? (
                    ''
                  ) : (
                    <u>
                      <Link
                        className="primary-link sm-link"
                        style={{ textAlign: 'left', display: 'block' }}
                        onClick={() => props.parentOnCloseDrawer()}
                        to={`/claim/${props.currentData.claim}/${props.currentData.notification_from}`}
                      >
                        View
                      </Link>
                    </u>
                  )}
                  <div className="flex mt-5 ai-center" style={{ lineHeight: '18px' }}>
                    {props.currentData.message_display_text.includes('hours') ||
                    props.currentData.message_display_text.includes('Now') ? (
                      <span className="ntime">{props.currentData.message_display_text} </span>
                    ) : (
                      <span className="ntime" style={{ fontSize: '11px' }}>
                        {props?.useCustomMoment
                          ? customMoment
                              .utc(props.currentData.message_display_text)
                              .tz(CURRENT_TIMEZONE)
                              .format('ddd L hh:mm A z')
                          : moment
                              .utc(props.currentData.message_display_text)
                              .local()
                              .format('ddd L hh:mm A') +
                            ' ' +
                            dateTimeZone()}
                      </span>
                    )}
                    {!props.currentData.read_status && (
                      <span className="nread-link">
                        <u>
                          <Link
                            className="primary-link sm-link"
                            onClick={() => props.parentReadNotification(props.currentData.id)}
                          >
                            Mark as Read
                          </Link>
                        </u>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!props.currentData.read_status && <div className="ndot"></div>}
          </>
        }
        className={props.currentData.read_status ? 'nrow read' : 'nrow'}
        type={'warning'}
        closable
        onClose={() => {
          props.parentDismissNotification(props.currentData.id);
          if (churnZeroData?.churnInfo) {
            churnZeroData?.churnInfo.trackEvent(
              `Dismissed Notification`,
              `User dismissed the notification`
            );
          }
        }}
      />
    </>
  );
}

export default CommonGlobalAlert;
