var CloseClaimType = {
  isClaimClose: false,
  isClaimCloseNewFlag: false,
  // claimStatus: "",
  closeInfoCardShow: false,
  claimStatusId: '',
};

const CloseClaimReducer = (state = CloseClaimType, action) => {
  switch (action.type) {
    case 'CLOSECLAIMTYPE':
      delete action.type;
      return {
        ...state,
        // isClaimClose: action.payload,
        isClaimClose: false,
        isClaimCloseNewFlag: action.payload.closeFlag,
        // claimStatus: action.payload.claimStatus,
        closeInfoCardShow: action.payload.closeInfoCardShow,
        claimStatusId: action.payload.claimStatusId,
      };
    default:
      return state;
  }
};
export default CloseClaimReducer;
