import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import whiteLogo from '../../assets/images/login_screenclaimdeck-logo-white.png';

const ErrorMessage = props => {
  const urlParams = new URLSearchParams(window.location.search);
  const error_title = urlParams.get('error_title');
  const error_message = urlParams.get('error_message');
  return (
    <>
      <div className="authentication flex">
        <aside className="flex ai-center flex-center">
          <img src={whiteLogo} alt="" width="145" />
        </aside>
        <div className="au-steps">
          <div id="div-page-not-found" className="scard-body not-found flex ai-center flex-center">
            <div className="" style={{ maxWidth: '490px' }}>
              <div className="emptydata">
                <h1>{error_title || 'Something went wrong!'}</h1>
                <p>{error_message || 'Session Unauthorized'}</p>
                <Button className="btn" type="primary">
                  <Link to={`login`} className="primary-link sm-link">
                    Login
                  </Link>
                </Button>
              </div>

              <div className="flex ai-center flex-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorMessage;
