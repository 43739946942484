var localSearchTYPE = {
  search_keyword: '',
};

const LocalSearchReducer = (state = localSearchTYPE, action) => {
  switch (action.type) {
    case 'localSearchTYPE':
      delete action.type;
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default LocalSearchReducer;
