const initialState = {
  selectedEvents: [], // Array of schedule event ids
};

const bulkEventUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'BULK_EVENTS_UPDATE_ADD':
      return {
        ...state,
        selectedEvents: state.selectedEvents.includes(action.payload)
          ? state.selectedEvents
          : [...state.selectedEvents, action.payload],
      };
    case 'BULK_EVENTS_UPDATE_REMOVE':
      return {
        ...state,
        selectedEvents: state.selectedEvents.filter(event => event !== action.payload),
      };
    case 'BULK_EVENTS_RESET':
      return {
        ...state,
        selectedEvents: [],
      };
    case 'BULK_EVENTS_UPDATE_SET':
      return {
        ...state,
        selectedEvents: action.payload,
      };
    default:
      return state;
  }
};
export default bulkEventUpdateReducer;
