import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: process.env.REACT_APP_DD_Application_ID,
  clientToken: process.env.REACT_APP_DD_Client_Token,
  site: process.env.REACT_APP_DD_Site,
  service: process.env.REACT_APP_DD_Service,
  env: process.env.REACT_APP_DD_Environment,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();
