var ReserveType = {
  isPrivate: false,
};

const ReserveTypeReducer = (state = ReserveType, action) => {
  switch (action.type) {
    case 'RESERVETYPE':
      delete action.type;
      return {
        ...state,
        isPrivate: action.payload,
      };
    default:
      return state;
  }
};
export default ReserveTypeReducer;
