var GLOBALCONTACT = {
  isContactAdded: false,
};

const GlobalContactReducer = (state = GLOBALCONTACT, action) => {
  switch (action.type) {
    case 'GLOBALCONTACT':
      delete action.type;
      return {
        ...state,
        isContactAdded: action.payload,
      };
    default:
      return state;
  }
};
export default GlobalContactReducer;
