import * as Crypto from 'crypto';

// TODO: Change this to a random string of 32 characters
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || '12345215124s15154231241524123po2';

export const encryptString = string => {
  // Generate a random initialization vector
  const iv = Crypto.randomBytes(16);

  // Create a cipher using the AES-256-CBC algorithm with the initialization vector
  const cipher = Crypto.createCipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), iv);

  // Encrypt the input string
  let encryptedString = cipher.update(string, 'utf8', 'hex');
  encryptedString += cipher.final('hex');

  // Return the encrypted string with the initialization vector prepended
  return iv.toString('hex') + encryptedString;
};

export const decryptString = encryptedString => {
  // Get the initialization vector from the encrypted string
  const iv = Buffer.from(encryptedString.slice(0, 32), 'hex');

  // Create a decipher using the AES-256-CBC algorithm with the initialization vector
  const decipher = Crypto.createDecipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), iv);

  // Decrypt the encrypted string
  let decryptedString = decipher.update(encryptedString.slice(32), 'hex', 'utf8');
  decryptedString += decipher.final('utf8');

  // Return the decrypted string
  return decryptedString;
};
// export const removeBrokenObject = e => {
//   let passValue;
//   let passNewValue;
//   // broken image tag
//   const matchString = '<img src="https://:0/">';

//   if (e && e.includes(matchString)) {
//     passValue = e.replace(/https:\/\/:0\//g, '');
//     passNewValue = passValue.replace(/<img src="">/g, '');
//   } else {
//     // passValue = e;
//     passNewValue = e;
//   }
//   return passNewValue;
// };

function urlify(text) {
  var urlRegex = /<img src="((https|http):\/\/[^\s]+)/g;
  return text.replace(urlRegex, function () {
    return '<img src=""';
  });
}
function removeWordObject(text) {
  var urlRegex = /<img src="\/\/:0"/g;
  return text.replace(urlRegex, function () {
    return '<img src=""';
  });
}

export const replaceQuote = text => {
  let apostrophe = /[’]/g;
  let singleQuote = /[‘]/g;
  let doubleQuote = /[“]/g;
  let doubleQuote1 = /[”]/g;

  if (!text) return '';

  return text
    .toString()
    .replaceAll(apostrophe, "'")
    .replaceAll(singleQuote, "'")
    .replaceAll(doubleQuote, '"')
    .replaceAll(doubleQuote1, '"');
};

export const removeBrokenObject = e => {
  let passValue = urlify(e);
  let passNewValue = removeWordObject(passValue);
  passNewValue = replaceQuote(passNewValue);
  return passNewValue;
};

export const downloadFile = (path, filename) => {
  try {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.target = '_blank';
    anchor.href = path;
    anchor.download = filename;
    // add unique id to the anchor
    anchor.id = 'auto-download-' + new Date().getTime();
    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
    return true;
  } catch (error) {
    console.error('Error downloading file:', error);
    return false;
  }
};

export const downloadBlob = (blob, filename) => {
  try {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading file:', error);
    return false;
  }
};
