import momentTZ from 'moment-timezone';
import { useSelector } from 'react-redux';

const useUserTimezone = () => {
  const loginUser = useSelector(state => state.auth.user);
  const timezone = loginUser?.timezone || momentTZ.tz.guess();
  const moment = momentTZ.tz.setDefault(timezone);
  const getNext30MinuteMark = () =>
    moment()
      .add(30 - (moment().minute() % 30), 'minutes')
      .startOf('minute');

  const CURRENT_TIMEZONE = timezone;

  const EVENT_START_DT_OBJ = getNext30MinuteMark();

  const EVENT_END_DT_OBJ = moment(EVENT_START_DT_OBJ).add(1, 'hour');

  const DEFAULT_EVENT_TIME_DATA = {
    dateTime: EVENT_START_DT_OBJ,
    tz: CURRENT_TIMEZONE,
  };

  const DEFAULT_EVENT_END_TIME_DATA = {
    dateTime: EVENT_END_DT_OBJ,
    tz: CURRENT_TIMEZONE,
  };

  const replaceTimezoneWithoutChangingTime = (dateTime, oldTz, newTz) => {
    const onlyDateTime = moment.tz(dateTime, oldTz).format('YYYY-MM-DDTHH:mm:ss');
    return moment.tz(onlyDateTime, newTz);
  };

  return {
    moment,
    DEFAULT_EVENT_TIME_DATA,
    DEFAULT_EVENT_END_TIME_DATA,
    EVENT_END_DT_OBJ,
    EVENT_START_DT_OBJ,
    CURRENT_TIMEZONE,
    getNext30MinuteMark,
    replaceTimezoneWithoutChangingTime,
  };
};

export default useUserTimezone;
