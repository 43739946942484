var QuickLinkType = [];

const QuickLinkReducer = (state = QuickLinkType, action) => {
  switch (action.type) {
    case 'QUICKLINKTYPE':
      return {
        ...state,
        QuickLinkType: action.payload,
      };
    default:
      return { ...state };
  }
};
export default QuickLinkReducer;
