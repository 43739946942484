var UserQuickLinkType = [];

const UserQuickLinkReducer = (state = UserQuickLinkType, action) => {
  switch (action.type) {
    case 'USERQUICKLINKTYPE':
      return {
        ...state,
        UserQuickLinkType: action.payload,
      };
    default:
      return { ...state };
  }
};
export default UserQuickLinkReducer;
