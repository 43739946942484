import React, { useState, Suspense, lazy, useRef, useEffect, useMemo } from 'react';
import { Route, withRouter, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import { Layout } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import BackToTop from 'react-back-to-top-button';
import moment from 'moment';
import {
  StyledHeader,
  CustomSider,
  StyledContent,
  RightLayout,
  TriggerHeader,
} from './routes_style';
import { useLocation } from 'react-router-dom';
import LoaderMain from './shared_components/loader/loader';

import MainHeader from './components/layout/header/header';
import MainSidebar from './components/layout/sidebar/sidebar';
import triangleexclamation from './assets/images/triangle-exclamation-solid.svg';

require('dotenv').config();

const Matter = lazy(() => import('./components/main_content/matter/matter'));
const MatterView = lazy(() => import('./components/main_content/matter/matter_view/matter_view'));

const Tasks = lazy(() => import('./components/main_content/tasks/tasks'));

const Contacts = lazy(() => import('./components/main_content/contacts/contacts'));
const Messenger = lazy(() => import('./components/main_content/messenger/messenger'));

const AdminSetting = lazy(() => import('./components/main_content/admin_setting/admin_setting'));

const AccountSetting = lazy(() =>
  import('./components/main_content/account_setting/account_setting')
);

const ErrorPage = lazy(() => import('./components/404_page/error_page'));
const ErrorMessage = lazy(() => import('./components/404_page/error_message'));

const GlobalSearch = lazy(() => import('./components/main_content/global_search/global_search'));

const ClaimScoreView = lazy(() =>
  import('./components/main_content/matter/claim_score/claim_score_view')
);

const BulkAccess = lazy(() => import('./components/main_content/staff_bulk_access/bulk_access'));
const Events = lazy(() => import('./components/main_content/events/events'));

const routes = [
  {
    exact: true,
    path: '/global-claim/:searchPara?/:filterPara?',
    component: () => <Matter />, // Global Claim Table Component
  },
  {
    exact: true,
    path: '/claim/:id/:tabkey/:subTab?/:searchPara?/:contactId?',
    component: () => <MatterView />, // Claim Details Component
  },
  {
    exact: true,
    path: '/global-task',
    component: () => <Tasks />,
  },
  {
    exact: true,
    path: '/global-contact/:searchPara?',
    component: () => <Contacts />,
  },
  {
    exact: true,
    path: '/messenger/:chat_type?/:group_url?',
    component: () => <Messenger />,
  },
  {
    exact: true,
    path: '/admin-setting',
    component: () => <AdminSetting />,
  },
  {
    exact: true,
    path: '/account-setting/:current_tab?',
    component: () => <AccountSetting />,
  },
  {
    exact: true,
    path: '/404',
    component: () => <ErrorPage />,
  },
  {
    exact: true,
    path: '/500',
    component: () => <ErrorPage internalServerError />,
  },
  {
    exact: true,
    path: '/401',
    component: () => <ErrorMessage />,
  },
  {
    exact: true,
    path: '/global-search',
    component: () => <GlobalSearch />,
  },
  {
    exact: true,
    path: '/claim_score/:id',
    component: () => <ClaimScoreView />,
  },
  // staff admin
  {
    exact: true,
    path: '/bulk-access-contacts',
    component: () => <BulkAccess />,
  },
  {
    exact: true,
    path: '/events',
    component: () => <Events />,
  },
];

function AppRouter(props) {
  const [collapsed, setCollapsed] = useState(true);
  const [dotFlag] = useState(false);
  const location = useLocation();
  const showFeedback = location.pathname.includes('messenger');
  const { on_trial, events_enable } = useSelector(state => state.auth.user);
  const appRoutes = useMemo(
    () => (events_enable ? routes : routes.filter(route => route.path !== '/events')),
    [events_enable]
  );

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  function useOutsideMenu(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCollapsed(true);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const menuRef = useRef(null);
  useOutsideMenu(menuRef);
  return (
    <Layout className={on_trial ? 'on_trial' : ''}>
      {!(
        props?.location?.pathname.includes('claim_score') ||
        props?.location?.pathname.includes('bulk-access-contacts')
      ) && (
        <CustomSider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className={collapsed ? 'off-sidebar' : 'on-sidebar'}
        >
          <div ref={menuRef}>
            {/* collapse */}
            <div
              className="side-logos"
              onClick={() => {
                toggle();
              }}
            >
              <span className="wordplay">Collapse</span>
              <span id="right-arrow-menu" className="ant-btn">
                <i className="fas fa-arrow-right" />
              </span>
            </div>
            <div id="black-box">
              <MainSidebar dotFlag={dotFlag} collapsed={collapsed} />
            </div>
          </div>
        </CustomSider>
      )}
      <RightLayout
        className={
          !(
            props?.location?.pathname.includes('claim_score') ||
            props?.location?.pathname.includes('bulk-access-contacts')
          )
            ? 'right-sidebar'
            : 'pl-0'
        }
      >
        {!(
          props?.location?.pathname.includes('claim_score') ||
          props?.location?.pathname.includes('bulk-access-contacts')
        ) && (
          <>
            <div className="trigger-header">
              <TriggerHeader className="icon-parent">
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                <RightOutlined
                  className="trigger trigger-icon"
                  type={collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={toggle}
                />
              </TriggerHeader>
              <StyledHeader className="top-notification">
                <MainHeader collapsedsidebar={collapsed} />
              </StyledHeader>
            </div>
          </>
        )}

        <StyledContent
          className={`${
            props.location.pathname.includes('404') ? 'remove-top-space-404' : 'remove-top-space'
          } loader-full-width`}
        >
          <Suspense className="loader" fallback={<LoaderMain></LoaderMain>}>
            <Switch>
              {appRoutes.map((singleRoute, index) => (
                <Route
                  exact={singleRoute.exact === false ? false : true}
                  key={index}
                  path={`${singleRoute.path}`}
                  component={singleRoute.component}
                  {...props}
                />
              ))}
              <Redirect from="/" to="/404" />
              <Redirect from="/" to="/401" />
            </Switch>
          </Suspense>
        </StyledContent>
        {!showFeedback && (
          <BackToTop showAt={300} speed={1000} easing="easeOutSine">
            <span className="back-top">
              <div className="round">
                <div id="cta">
                  <span className="arrow primera next "></span>
                  <span className="arrow segunda next "></span>
                </div>
              </div>
            </span>
          </BackToTop>
        )}
      </RightLayout>
    </Layout>
  );
}

export default withRouter(AppRouter);
