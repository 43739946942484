import styled from 'styled-components';
import { theme, screen } from '../../constants/constant';
import { Card } from 'antd';

export const MyCard = styled(Card)`
  border: 1px solid ${theme.light_gray_border};

  & .ant-card-body {
    padding: 0px !important;
  }
  @media only screen and (max-width: ${screen.md}) {
    &.custom-card-title .ant-row-flex-start {
      align-content: flex-start;
      flex-direction: column;
      > div {
        width: 100%;
        div {
          padding-left: 0;
        }
      }
    }
  }
  .pointer {
    display: inline-block;
    cursor: pointer;
  }
`;

export const MainSection = styled.div`
  padding: 15px 30px 30px 30px;
  @media only screen and (max-width: 600px) {
    .mobile-center {
      text-align: center;
    }
  }
  .project--shopdrawing {
    .quotes-card {
      padding-top: 25px;
      min-height: 240px;
    }
    .bold {
      font-family: 'Segoe UI Bold';
    }
  }
`;
