var FormModelType = {
  isVisible: false,
  slug: 'Add New',
  isSuccessModalShow: false,
  claimId: '',
  isClaimVisible: false,
  isClaimSuccessModalShow: false,
};

const FormModelTypeReducer = (state = FormModelType, action) => {
  switch (action.type) {
    case 'FORMTYPE':
      return {
        ...state,
        ...action,
      };
    default:
      return {
        ...state,
        isVisible: false,
        isSuccessModalShow: false,
        claimId: '',
        isClaimVisible: false,
        isClaimSuccessModalShow: false,
      };
  }
};
export default FormModelTypeReducer;
