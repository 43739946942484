var NotificationType = {
  IsGlabalNotificationAvailable: false,
  TaskDotShow: false,
  MatterDotShow: false,
  SyatemDotShow: false,
  EventDotShow: false,
};

const GlobalNotificationTypeReducer = (state = NotificationType, action) => {
  switch (action.type) {
    case 'NOTIFICATIONTYPE':
      delete action.type;
      return {
        ...state,
        IsGlabalNotificationAvailable: action.payload.global_notification,
        TaskDotShow: action.payload.task_request,
        MatterDotShow: action.payload.matter,
        SyatemDotShow: action.payload.system,
        EventDotShow: action.payload.event,
      };
    default:
      return state;
  }
};
export default GlobalNotificationTypeReducer;
