export function getDomainName(address) {
  return address.substring(address.lastIndexOf('@') + 1);
}

export function filterByDomain(useEmail) {
  const { REACT_APP_MY_ENV, REACT_APP_CHURNZERO_ALLOWED_EMAILS } = process.env;
  let email = useEmail.toLowerCase();
  let validEmail = true;

  if (REACT_APP_MY_ENV === 'production') {
    if (
      email.includes('@yopmail.com') ||
      email.includes('@claim-deck.com') ||
      email.includes('@syzygylegaltech.com') ||
      email.includes('@codal.com') ||
      email.includes('@codaldemo.com')
    ) {
      if (REACT_APP_CHURNZERO_ALLOWED_EMAILS.includes(email)) {
        validEmail = true;
      } else {
        validEmail = false;
      }
    }
  } else {
    validEmail = false;
  }
  return validEmail;
}
