import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { internetFlag } from '../../redux/actions/internetFlagAction';

import whiteLogo from '../../assets/images/login_screenclaimdeck-logo-white.png';

function InternetErrorPage() {
  const dispatchCall = useDispatch();

  const retry = () => {
    // store.dispatch(internetFlag({ is_internet_on: window?.navigator?.onLine }));
    dispatchCall(
      internetFlag({
        is_internet_on: window?.navigator?.onLine,
      })
    );
    window.location.reload();
  };

  return (
    <>
      <div className="authentication flex">
        <aside className="flex ai-center flex-center">
          <img src={whiteLogo} alt="" width="145" />
        </aside>
        <div className="au-steps">
          <div id="div-page-not-found" className="scard-body not-found flex ai-center flex-center">
            <div className="" style={{ maxWidth: '490px' }}>
              <div className="emptydata">
                <h1>Sorry, it looks like you’re unable to connect to the internet.</h1>
                <p>Check your internet connection and retry below.</p>
                <Button className="btn" type="submit" onClick={retry} aria-label="submit">
                  Retry
                </Button>
              </div>

              <div className="flex ai-center flex-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InternetErrorPage;
