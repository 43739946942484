import React, { useState, createContext, useContext } from 'react';

const ChurnContext = createContext(null);

export const ChurnZeroProvider = ({ children }) => {
  const [churnInfo, setChurnInfo] = useState();

  const login = value => {
    setChurnInfo(value);
  };

  const logout = () => {
    setChurnInfo(null);
  };

  return (
    <ChurnContext.Provider value={{ churnInfo, login, logout }}>{children}</ChurnContext.Provider>
  );
};

export const useChurnZero = () => {
  return useContext(ChurnContext);
};
