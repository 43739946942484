const initialState = {
  data: null,
  loading: false,
  error: null,
  deleteLoading: false,
};

const eventDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EVENT_DETAILS_START':
      return {
        ...state,
        loading: true,
      };
    case 'GET_EVENT_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case 'GET_EVENT_DETAILS_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'SET_SELECTED_EVENT':
      return {
        ...state,
        data: action.payload,
      };
    case 'RESET_EVENT_DETAILS':
      return {
        ...state,
        ...initialState,
      };
    case 'DELETE_EVENT_DETAILS_START':
      return {
        ...state,
        deleteLoading: true,
      };
    case 'DELETE_EVENT_DETAILS_SUCCESS':
      return {
        ...state,
        deleteLoading: false,
      };
    case 'DELETE_EVENT_DETAILS_ERROR':
      return {
        ...state,
        deleteLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default eventDetailsReducer;
