var OverviewNotificationType = {};

const OverviewNotificationReducer = (state = OverviewNotificationType, action) => {
  switch (action.type) {
    case 'OVERVIEWNOTIFICATIONTYPE':
      return {
        ...state,
        OverviewNotificationType: action.payload,
      };
    default:
      return { ...state };
  }
};
export default OverviewNotificationReducer;
