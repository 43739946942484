const initialState = {
  data: null,
  loading: false,
  error: null,
  totalCount: 0,
  updateLoading: false,
  applyFilters: null,
  creators: [],
  categories: [],
  otherCategories: [],
  claimAssociationList: [],
  adjustersList: [],
  showResultsButton: false,
  countLoading: false,
  isFilterApply: false,
};

const eventsFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EVENT_FILTERS_START':
      return {
        ...state,
        loading: true,
      };
    case 'GET_EVENT_FILTERS_SUCCESS':
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case 'GET_EVENT_FILTERS_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'UPDATE_EVENT_FILTERS_START':
      return {
        ...state,
        updateLoading: true,
      };
    case 'UPDATE_EVENT_FILTERS_SUCCESS':
      return {
        ...state,
        ...action.payload,
        updateLoading: false,
      };
    case 'UPDATE_EVENT_FILTERS_ERROR':
      return {
        ...state,
        updateLoading: false,
        error: action.payload,
      };
    case 'SET_SELECTED_FILTERS_COUNT':
      return {
        ...state,
        totalCount: action.payload,
        countLoading: false,
      };
    case 'SET_SELECTED_FILTERS':
      return {
        ...state,
        data: action.payload,
      };
    case 'SET_APPLIED_FILTERS':
      return {
        ...state,
        applyFilters: action.payload,
      };
    case 'SET_EVENT_CREATORS_LIST':
      return {
        ...state,
        creators: action.payload,
      };
    case 'SET_EVENT_CATEGORIES_LIST':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_SHOW_LIST_BUTTON':
      return {
        ...state,
        showResultsButton: action.payload,
      };
    case 'SET_FILTERS_COUNT_LOADING':
      return {
        ...state,
        countLoading: action.payload,
      };
    case 'SET_ADJUSTERS_LIST':
      return {
        ...state,
        adjustersList: action.payload,
      };
    case 'GET_CLAIMS_LIST_START':
      return {
        ...state,
        loading: true,
      };
    case 'GET_CLAIMS_LIST_SUCCESS':
      return {
        ...state,
        claimAssociationList: action.payload,
        loading: false,
      };
    case 'GET_CLAIMS_LIST_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'RESET_FILTERS':
      return {
        ...state,
        ...initialState,
        creators: state?.creators,
        categories: state?.categories,
        otherCategories: state?.categories,
        claimAssociationList: state?.claimAssociationList,
        adjustersList: state?.adjustersList,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default eventsFiltersReducer;
