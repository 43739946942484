var ClaimName = {
  currentClaimName: '',
};

const ClaimNameReducer = (state = ClaimName, action) => {
  switch (action.type) {
    case 'CLAIMNAME':
      delete action.type;
      return {
        ...state,
        currentClaimName: action.payload,
      };
    default:
      return state;
  }
};
export default ClaimNameReducer;
