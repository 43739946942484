var globalSearchTYPE = {
  search_keyword: '',
  total_count: 0,
  claim_count: 0,
  contact_count: 0,
  document_count: 0,
};

const GlobalSearchReducer = (state = globalSearchTYPE, action) => {
  switch (action.type) {
    case 'globalSearchTYPE':
      delete action.type;
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default GlobalSearchReducer;
