var userType = {
  currentUser: 'client',
};

const UserTypeReducer = (state = userType, action) => {
  switch (action.type) {
    case 'USERTYPE':
      delete action.type;
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};
export default UserTypeReducer;
