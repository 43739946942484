var CLAIMADJUSTER = {
  isClaimAdjuster: false,
};

const GlobalClaimAdjuster = (state = CLAIMADJUSTER, action) => {
  switch (action.type) {
    case 'CLAIMADJUSTER':
      delete action.type;
      return {
        ...state,
        isClaimAdjuster: action.payload.isclaim,
      };
    default:
      return state;
  }
};
export default GlobalClaimAdjuster;
