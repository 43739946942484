import React from 'react';
import { Button } from 'antd';

import whiteLogo from '../../assets/images/login_screenclaimdeck-logo-white.png';

// import jstlogo from './assets/images/jst-logo.svg';
// import logo from './assets/images/logo.svg';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    // this.state = { error: true, errorInfo: 'LOREM' };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <>
          <div className="authentication flex">
            <aside className="flex ai-center flex-center">
              <img src={whiteLogo} alt="" width="145" />
            </aside>
            <div className="au-steps">
              <div
                id="div-page-not-found"
                className="scard-body not-found flex ai-center flex-center"
              >
                <div className="" style={{ maxWidth: '950px' }}>
                  <div className="emptydata">
                    <h1>Oops, something went wrong!</h1>
                    <p> System may have new updates, please refresh the page. .</p>
                    <Button
                      className="btn"
                      type="submit"
                      onClick={() => {
                        window.location.reload();
                      }}
                      aria-label="submit"
                    >
                      Refresh
                    </Button>
                    <p>
                      <details style={{ marginTop: '15px' }}>
                        The page you were looking for could not be found. It might have been
                        removed, renamed, or did not exist in the first place.
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                      </details>
                    </p>
                  </div>

                  <div className="flex ai-center flex-center"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
