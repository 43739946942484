import React from 'react';
// import Loader from "react-loader-spinner";
import { Spin } from 'antd';
import 'antd/dist/antd.css';
import { SyncOutlined } from '@ant-design/icons';
// import Icon from '@ant-design/icons';
// import spinnerIMG from '../../assets/images/spinnerIMG.gif';

const antIcon = <SyncOutlined style={{ fontSize: 50 }} spin />;

// custom loader
// const customLoader = () => (
//     <img src={spinnerIMG} />
// );

// const antIcon = <Icon component={customLoader} style={{ fontSize: 50 }} />

const ComponentLoader = props => {
  return (
    <Spin indicator={antIcon} className={props.className} size="large" height={100} width={100} />
    // <Loader
    //     className={props.className}
    //     type="Circles"
    //     color="#5160CC"
    //     height={40}
    //     width={40}>

    // </Loader>
  );
};

export default ComponentLoader;
