// color themes
export const theme = {
  white: '#ffffff',
  black: '#000000',
  primary: '#5463D2',
};
// fonts size
export const fonts = {
  font_10: '10px',
};
// Screen Size
export const screen = {
  xs: '480px',
  xxs: '500px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
  xxxl: '2000px',
};
