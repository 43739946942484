import React from 'react';
// import Loader from "react-loader-spinner";
import { Spin } from 'antd';
import 'antd/dist/antd.css';
import { SyncOutlined } from '@ant-design/icons';

const TableLoader = props => {
  return (
    <Spin
      indicator={<SyncOutlined style={{ fontSize: props?.iconSize || 50 }} spin />}
      className={props.className}
      size="large"
      height={40}
      width={40}
      {...props}
    />
    // <Loader
    //     className={props.className}
    //     type="Bars"
    //     color="#5160CC"
    //     height={40}
    //     width={40}></Loader>
  );
};

export default TableLoader;
