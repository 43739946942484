import React from 'react';
import { LoaderWrapper } from '../../routes_style';
// import Loader from "react-loader-spinner";
import { Spin } from 'antd';
import 'antd/dist/antd.css';
import { SyncOutlined } from '@ant-design/icons';

const antIcon = <SyncOutlined style={{ fontSize: 50 }} spin />;

function LoaderMain() {
  return (
    <LoaderWrapper className="loader2">
      <Spin indicator={antIcon} className="global-loader" size="large" height={80} width={80} />
      {/* <Loader
                className="global-loader"
                type="Bars"
                color="#5160CC"
                height={80}
                width={80}></Loader> */}
    </LoaderWrapper>
  );
}

export default LoaderMain;
