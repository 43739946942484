/* In-built Packages */
import React, { useRef, useState, Suspense, useCallback, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Drawer, Tabs, notification } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import ApiUtils from '../../../helpers/apiUtils';
import TabLoader from '../../../shared_components/loader/tab_loader';
import CommonGlobalAlert from './common_global_alert';
import { notificationType } from '../../../redux/actions/globalNotificationAction';
import TableLoader from '../../../shared_components/loader/table-loader';
import { useChurnZero } from '../../auth/churnZero';

const TabPane = Tabs.TabPane;

function SelectDrawer(props) {
  const api = useRef(new ApiUtils(true, true, true));
  let third_party_user_type = useSelector(state => state.auth.user.third_party_user);
  const showDrawer = props.visible;
  const [tabKey, setTabKey] = useState(third_party_user_type ? 'system' : 'task_request');
  const [allAlert, setAllAlert] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [nextLoad, setNextLoad] = useState(0);
  const IsTaskDotShow = useSelector(state => state.notification.TaskDotShow);
  const IsMatterDotShow = useSelector(state => state.notification.MatterDotShow);
  const IsSyatemDotShow = useSelector(state => state.notification.SyatemDotShow);
  const IsEventDotShow = useSelector(state => state.notification.EventDotShow);
  let login_user_role_type = useSelector(state => state.auth.user.login_user_role_type);
  const isEventsEnable = useSelector(state => state?.auth?.user?.events_enable);
  const dispatch = useDispatch();
  const churnZeroData = useChurnZero();

  /**
   * get All Global Notifications
   */
  const getAllNotification = async () => {
    setTableDataLoading(true);
    try {
      let req_data = {
        limit: 20,
        offset: 20 * (page - 1),
        filter_by: tabKey.toString(),
      };
      const res = await api.current.allGlobalNotification({ ...req_data });
      if (res.status === 200) {
        if (res.data) {
          let temp;
          if (page !== 1) {
            temp = [...allAlert];
          } else {
            temp = [];
          }
          if (res.data.results && res.data.results.length > 0) {
            res.data.results.forEach(element => {
              temp.push(element);
            });
            setAllAlert(temp);
            if (res.data.next) {
              setNextLoad(true);
            } else {
              setNextLoad(false);
            }
          }
        }
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Page not found',
        });
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    } finally {
      setTableDataLoading(false);
    }
  };

  const stableHandlerAllNotification = useCallback(getAllNotification, [tabKey, page]);

  useEffect(() => {
    stableHandlerAllNotification();
  }, [stableHandlerAllNotification]);

  /**
   * dismiss all Gloabl notifications
   */
  const getDismissAllNotification = async () => {
    try {
      let req_data = {
        filter_by: tabKey.toString(),
      };
      const res = await api.current.dismissAllNotification({ ...req_data });
      if (res.status === 200) {
        let notificationData = {};
        if (tabKey.toString() === 'task_request') {
          notificationData = {
            global_notification:
              IsMatterDotShow === true || IsSyatemDotShow === true || IsEventDotShow === true
                ? true
                : false,
            matter: IsMatterDotShow,
            task_request: false,
            system: IsSyatemDotShow,
            event: IsEventDotShow,
          };
        } else if (tabKey.toString() === 'matter') {
          notificationData = {
            global_notification:
              IsTaskDotShow === true || IsSyatemDotShow === true || IsEventDotShow === true
                ? true
                : false,
            matter: false,
            task_request: IsTaskDotShow,
            system: IsSyatemDotShow,
            event: IsEventDotShow,
          };
        } else if (tabKey.toString() === 'system') {
          notificationData = {
            global_notification:
              IsMatterDotShow === true || IsTaskDotShow === true || IsEventDotShow === true
                ? true
                : false,
            matter: IsMatterDotShow,
            task_request: IsTaskDotShow,
            system: false,
            event: IsEventDotShow,
          };
        } else if (tabKey.toString() === 'event') {
          notificationData = {
            global_notification:
              IsMatterDotShow === true || IsTaskDotShow === true || IsSyatemDotShow === true
                ? true
                : false,
            matter: IsMatterDotShow,
            task_request: IsTaskDotShow,
            system: IsSyatemDotShow,
            event: false,
          };
        }
        dispatch(notificationType(notificationData));
        setAllAlert([]);
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Page not found',
        });
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    } finally {
    }
  };

  /**
   * read all notifications
   */
  const getReadAllNotification = async () => {
    try {
      let req_data = {
        filter_by: tabKey.toString(),
      };
      const res = await api.current.readAllNotification({ ...req_data });
      if (res.status === 200) {
        let notificationData = {};
        getAllNotification();
        if (tabKey.toString() === 'task_request') {
          notificationData = {
            global_notification:
              IsMatterDotShow === true || IsSyatemDotShow === true || IsEventDotShow === true
                ? true
                : false,
            matter: IsMatterDotShow,
            task_request: false,
            system: IsSyatemDotShow,
            event: IsEventDotShow,
          };
        } else if (tabKey.toString() === 'matter') {
          notificationData = {
            global_notification:
              IsTaskDotShow === true || IsSyatemDotShow === true || IsEventDotShow === true
                ? true
                : false,
            matter: false,
            task_request: IsTaskDotShow,
            system: IsSyatemDotShow,
            event: IsEventDotShow,
          };
        } else if (tabKey.toString() === 'system') {
          notificationData = {
            global_notification:
              IsMatterDotShow === true || IsTaskDotShow === true || IsEventDotShow === true
                ? true
                : false,
            matter: IsMatterDotShow,
            task_request: IsTaskDotShow,
            system: false,
            event: IsEventDotShow,
          };
        } else if (tabKey.toString() === 'event') {
          notificationData = {
            global_notification:
              IsMatterDotShow === true || IsTaskDotShow === true || IsSyatemDotShow === true
                ? true
                : false,
            matter: IsMatterDotShow,
            task_request: IsTaskDotShow,
            system: IsSyatemDotShow,
            event: false,
          };
        }
        dispatch(notificationType(notificationData));
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Page not found',
        });
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    } finally {
    }
  };

  /**
   *
   * @param {*} notificationId
   * read particuler notification
   */
  const readNotification = async notificationId => {
    try {
      let req_data = {
        read_status: true,
        filter_by: tabKey.toString(),
      };
      const res = await api.current.updateNotification(notificationId, { ...req_data });
      if (res.status === 200) {
        // temp alerts set
        let tempAlert = [...allAlert];
        let objIndex = tempAlert.findIndex(obj => obj.id === notificationId);
        tempAlert[objIndex].read_status = true;
        setAllAlert(tempAlert);
        let notificationData = {};
        if (tabKey.toString() === 'task_request') {
          notificationData = {
            global_notification: res.data ? res.data.global : false,
            matter: IsMatterDotShow,
            task_request: res.data ? res.data.task_request : false,
            system: IsSyatemDotShow,
          };
        } else if (tabKey.toString() === 'matter') {
          notificationData = {
            global_notification: res.data ? res.data.global : false,
            matter: res.data ? res.data.matter : false,
            task_request: IsTaskDotShow,
            system: IsSyatemDotShow,
          };
        } else if (tabKey.toString() === 'system') {
          notificationData = {
            global_notification: res.data ? res.data.global : false,
            matter: IsMatterDotShow,
            task_request: IsTaskDotShow,
            system: res.data && res.data.system,
          };
        } else if (tabKey.toString() === 'event') {
          notificationData = {
            global_notification: res.data ? res.data.global : false,
            matter: IsMatterDotShow,
            task_request: IsTaskDotShow,
            system: IsSyatemDotShow,
            event: res.data && res.data.event,
          };
        }
        dispatch(notificationType(notificationData));
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);

      if (err.response) {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: err.response.data.message,
        });
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    } finally {
      setTableDataLoading(false);
    }
  };

  /**
   *
   * @param {*} notificationId
   * dismiss perticular notification
   */
  const dismissNotification = async notificationId => {
    try {
      let req_data = {
        filter_by: tabKey.toString(),
      };
      const res = await api.current.dismissNotification(notificationId, { ...req_data });
      if (res.status === 200) {
        if (res.data) {
          let notificationData = {};
          if (tabKey.toString() === 'task_request') {
            notificationData = {
              global_notification: res.data ? res.data.global : false,
              matter: IsMatterDotShow,
              task_request: res.data && res.data.task_request,
              system: IsSyatemDotShow,
            };
          } else if (tabKey.toString() === 'matter') {
            notificationData = {
              global_notification: res.data ? res.data.global : false,
              matter: res.data && res.data.matter,
              task_request: IsTaskDotShow,
              system: IsSyatemDotShow,
            };
          } else if (tabKey.toString() === 'system') {
            notificationData = {
              global_notification: res.data ? res.data.global : false,
              matter: IsMatterDotShow,
              task_request: IsTaskDotShow,
              system: res.data && res.data.system,
            };
          } else if (tabKey.toString() === 'event') {
            notificationData = {
              global_notification: res.data ? res.data.global : false,
              matter: IsMatterDotShow,
              task_request: IsTaskDotShow,
              system: IsSyatemDotShow,
              event: res.data && res.data.event,
            };
          }
          dispatch(notificationType(notificationData));
        }
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: res.data.message,
        });
      }
    } catch (err) {
      console.log(err);

      if (err.response) {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: err.response.data.message,
        });
      } else {
        notification.error({
          icon: <i className="fas fa-times-circle"></i>,
          className: 'error-notification',
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    } finally {
      setTableDataLoading(false);
    }
  };

  /**
   *
   * @param {*} key
   * tab change method call
   */
  const callbackTabChange = async key => {
    setTabKey(key);
    setAllAlert([]);
    setPage(1);
    setNextLoad(0);
  };

  useEffect(() => {
    // track event using churnZero
    if (tabKey.toString() === 'system') {
      if (churnZeroData?.churnInfo) {
        churnZeroData?.churnInfo.trackEvent(
          `Viewed System Notifications`,
          `User clicked on "System" tab`
        );
      }
    }
    if (tabKey.toString() === 'matter') {
      if (churnZeroData?.churnInfo) {
        churnZeroData?.churnInfo.trackEvent(
          `Viewed Claim Updates Notifications`,
          `User clicked on "Claim Updates" tab`
        );
      }
    }
  }, [churnZeroData.churnInfo, tabKey]);

  /**
   * drawer close method
   */
  const onCloseDrawer = () => {
    props.onCloseDrawer(true);
    if (churnZeroData.churnInfo) {
      churnZeroData.churnInfo.trackEvent(
        `Viewed Tasks & Requests Notifications`,
        `User clicked on Notification Bell`
      );
    }
  };

  return (
    <Drawer
      width={640}
      visible={showDrawer}
      placement="right"
      getContainer={false}
      className="notification-drawer"
      {...props}
      closeIcon={
        <>
          <p onClick={onCloseDrawer}>
            Close Notifications &nbsp; <i className="far fa-times"></i>
          </p>
        </>
      }
    >
      <h3>Notifications</h3>
      <hr />

      {/* tabs */}
      <Tabs
        activeKey={tabKey.toString()}
        onChange={callbackTabChange}
        animated={true}
        className="notification-drawer"
        title="Notifications"
      >
        {!third_party_user_type && (
          <TabPane
            tab={
              <span>
                Tasks & Requests
                {IsTaskDotShow && <span className="ndot"></span>}
              </span>
            }
            key="task_request"
          >
            <Suspense fallback={<TabLoader className="global-loader" />}>
              <div className="card-container">
                {tableDataLoading && <TableLoader className="global-loader1" />}
                <div className="ntlist">
                  {allAlert.length > 0 ? (
                    <div className="notify-list">
                      <div className="nline">
                        <Link className="active" onClick={() => getReadAllNotification()}>
                          Mark All as Read
                        </Link>
                        <Link onClick={() => getDismissAllNotification()}>Dismiss All</Link>
                      </div>
                      <div
                        id="scrollableDiv"
                        style={{
                          height: 322,
                          overflow: 'auto',
                        }}
                      >
                        <InfiniteScroll
                          dataLength={allAlert && allAlert.length}
                          next={() => {
                            setPage(page + 1);
                          }}
                          hasMore={nextLoad}
                          loader={tableDataLoading}
                          // height={400}
                          scrollableTarget="scrollableDiv"
                          initialScrollY={0}
                        >
                          {allAlert.map((item, index) => (
                            <CommonGlobalAlert
                              key={index}
                              currentData={item}
                              IsLinkVisible={false}
                              parentReadNotification={readNotification}
                              parentDismissNotification={dismissNotification}
                              parentOnCloseDrawer={onCloseDrawer}
                              notificationType="task"
                            />
                          ))}
                        </InfiniteScroll>
                      </div>
                    </div>
                  ) : (
                    !tableDataLoading && (
                      <div className="nodatafound">
                        <h3>
                          Tasks & Requests are <br /> all caught up!
                        </h3>
                      </div>
                    )
                  )}
                </div>
              </div>
            </Suspense>
          </TabPane>
        )}

        {!third_party_user_type && (
          <TabPane
            tab={
              <span className="notify-ico treqcell sm-trcell">
                <span>
                  {login_user_role_type === 'law_firm' ? 'Matter Updates' : 'Claim Updates'}{' '}
                </span>
                {IsMatterDotShow && <span className="ndot"></span>}
              </span>
            }
            key="matter"
          >
            <Suspense fallback={<TabLoader className="global-loader" />}>
              {tabKey === 'matter' && (
                <div className="card-container">
                  {tableDataLoading && <TableLoader className="global-loader1" />}
                  <div className="ntlist">
                    {allAlert.length > 0 ? (
                      <div className="notify-list">
                        <div className="nline">
                          <Link className="active" onClick={() => getReadAllNotification()}>
                            Mark All as Read
                          </Link>
                          <Link onClick={() => getDismissAllNotification()}>Dismiss All</Link>
                        </div>
                        <div
                          id="scrollableDivMatter"
                          style={{
                            height: 325,
                            overflow: 'auto',
                          }}
                        >
                          <InfiniteScroll
                            dataLength={allAlert && allAlert.length}
                            next={() => {
                              setPage(page + 1);
                            }}
                            hasMore={nextLoad}
                            loader={tableDataLoading}
                            // height={400}
                            scrollableTarget="scrollableDivMatter"
                            initialScrollY={0}
                          >
                            {allAlert.map((item, index) => (
                              <CommonGlobalAlert
                                key={index}
                                currentData={item}
                                IsLinkVisible={false}
                                parentReadNotification={readNotification}
                                parentDismissNotification={dismissNotification}
                                parentOnCloseDrawer={onCloseDrawer}
                              />
                            ))}
                          </InfiniteScroll>
                        </div>
                      </div>
                    ) : (
                      !tableDataLoading && (
                        <div className="nodatafound">
                          <h3>
                            {login_user_role_type === 'law_firm'
                              ? 'Matter Updates are '
                              : 'Claim Updates are '}{' '}
                            <br /> all caught up!
                          </h3>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </Suspense>
          </TabPane>
        )}

        <TabPane
          tab={
            <span className="notify-ico treqcell sm-trcell">
              <span>System</span>
              {IsSyatemDotShow && <span className="ndot"></span>}
            </span>
          }
          key="system"
        >
          <Suspense fallback={<TabLoader className="global-loader" />}>
            {tabKey === 'system' && (
              <div className="card-container">
                {tableDataLoading && <TableLoader className="global-loader1" />}
                <div className="ntlist">
                  {allAlert.length > 0 ? (
                    <div className="notify-list">
                      <div className="nline">
                        <Link className="active" onClick={() => getReadAllNotification()}>
                          Mark All as Read
                        </Link>
                        <Link onClick={() => getDismissAllNotification()}>Dismiss All</Link>
                      </div>
                      <div
                        id="scrollableDivSystem"
                        style={{
                          height: 322,
                          overflow: 'auto',
                        }}
                      >
                        <InfiniteScroll
                          dataLength={allAlert && allAlert.length}
                          next={() => {
                            setPage(page + 1);
                          }}
                          hasMore={nextLoad}
                          loader={tableDataLoading}
                          // height={400}
                          scrollableTarget="scrollableDivSystem"
                          initialScrollY={0}
                        >
                          {allAlert.map((item, index) => (
                            <CommonGlobalAlert
                              key={index}
                              currentData={item}
                              IsLinkVisible={false}
                              parentReadNotification={readNotification}
                              parentDismissNotification={dismissNotification}
                              parentOnCloseDrawer={onCloseDrawer}
                            />
                          ))}
                        </InfiniteScroll>
                      </div>
                    </div>
                  ) : (
                    !tableDataLoading && (
                      <div className="nodatafound">
                        <h3>
                          System is <br /> all caught up!
                        </h3>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </Suspense>
        </TabPane>

        {isEventsEnable && (
          <TabPane
            tab={
              <span className="notify-ico treqcell sm-trcell">
                <span>Event</span>
                {IsEventDotShow && <span className="ndot"></span>}
              </span>
            }
            key="event"
          >
            <Suspense fallback={<TabLoader className="global-loader" />}>
              {tabKey === 'event' && (
                <div className="card-container">
                  {tableDataLoading && <TableLoader className="global-loader1" />}
                  <div className="ntlist">
                    {allAlert.length > 0 ? (
                      <div className="notify-list">
                        <div className="nline">
                          <Link className="active" onClick={() => getReadAllNotification()}>
                            Mark All as Read
                          </Link>
                          <Link onClick={() => getDismissAllNotification()}>Dismiss All</Link>
                        </div>
                        <div
                          id="scrollableDivEvent"
                          style={{
                            height: 322,
                            overflow: 'auto',
                          }}
                        >
                          <InfiniteScroll
                            dataLength={allAlert && allAlert.length}
                            next={() => {
                              setPage(page + 1);
                            }}
                            hasMore={nextLoad}
                            loader={tableDataLoading}
                            // height={400}
                            scrollableTarget="scrollableDivEvent"
                            initialScrollY={0}
                          >
                            {allAlert.map((item, index) => (
                              <CommonGlobalAlert
                                key={index}
                                currentData={item}
                                IsLinkVisible={false}
                                parentReadNotification={readNotification}
                                parentDismissNotification={dismissNotification}
                                parentOnCloseDrawer={onCloseDrawer}
                                useCustomMoment={true}
                              />
                            ))}
                          </InfiniteScroll>
                        </div>
                      </div>
                    ) : (
                      !tableDataLoading && (
                        <div className="nodatafound">
                          <h3>
                            Event is <br /> all caught up!
                          </h3>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </Suspense>
          </TabPane>
        )}
      </Tabs>
    </Drawer>
  );
}

export default SelectDrawer;
