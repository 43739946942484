import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './scss/common.scss';
import './datadog.js';
import App from './App';
import { ChurnZeroProvider } from './components/auth/churnZero';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configStore from './redux/store/configStore';

const store = configStore();

console.log = () => {};

ReactDOM.render(
  <Provider store={store}>
    <ChurnZeroProvider>
      <App />
    </ChurnZeroProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
