var TaskRequestCount = {
  pending_request: 0,
  task_to_complete_Adjuster: 0,
  incoming_request: 0,
  task_to_complete_Attorney: 0,
};

const TaskRequestCountReducer = (state = TaskRequestCount, action) => {
  switch (action.type) {
    case 'TASKREQUESTCOUNTTYPE':
      delete action.type;
      return {
        ...state,
        pending_request: action.payload.pending_request,
        task_to_complete_Adjuster: action.payload.task_to_complete_Adjuster,
        incoming_request: action.payload.incoming_request,
        task_to_complete_Attorney: action.payload.task_to_complete_Attorney,
      };
    default:
      return state;
  }
};
export default TaskRequestCountReducer;
