import React from 'react';
// import Loader from "react-loader-spinner";
import { Spin } from 'antd';
import { MainSection, MyCard } from './tab_loader_style';
import 'antd/dist/antd.css';
import { SyncOutlined } from '@ant-design/icons';

const antIcon = <SyncOutlined style={{ fontSize: 50 }} spin />;

const TabLoader = props => {
  return (
    <div>
      <MainSection>
        <MyCard bordered={false}>
          <Spin
            indicator={antIcon}
            className={props.className}
            size="large"
            height={40}
            width={40}
          />
          {/* <Loader type="Bars"
                        className={props.className}
                        color="#5160CC"
                        height={40}
                        width={40}></Loader> */}
        </MyCard>
      </MainSection>
    </div>
  );
};

export default TabLoader;
