import { Client } from '@revenuegrid/churnzerojs';

const churnZero = async (accountId, contactId) => {
  const client = await Client.connect({
    url: process.env.REACT_APP_CHURNZERO_URL, // or eu1analytics.churnzero.net/churnzero.js
    apiKey: process.env.REACT_APP_CHURNZERO_API_KEY,
    accountId: accountId,
    contactId: contactId,
  });
  return client;
};
export default churnZero;
