var internetFlagTYPE = {
  is_internet_on: true,
};

const InternetFlagReducer = (state = internetFlagTYPE, action) => {
  switch (action.type) {
    case 'internetFlagTYPE':
      delete action.type;
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default InternetFlagReducer;
