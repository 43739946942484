var StaticPageType = {
  isVisible: false,
  slug: '',
};

const StaticPageTypeReducer = (state = StaticPageType, action) => {
  switch (action.type) {
    case 'STATICPAGE':
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};
export default StaticPageTypeReducer;
