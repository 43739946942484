import 'antd/dist/antd.css';
import { createBrowserHistory } from 'history';
import React, { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SendBirdProvider } from 'sendbird-uikit';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import AppRouter from './routes';
import LoaderMain from './shared_components/loader/loader';
import ErrorMessage from './components/404_page/error_message';
import InternetErrorPage from './components/404_page/internet_error';
import { useEffect } from 'react';
import { internetFlag } from './redux/actions/internetFlagAction';
import { ErrorBoundary } from './components/404_page/error_boundary';

// Modal Lazy Load
const StaticPage = lazy(() => import('./components/main_content/static_page/static_page'));

// Auth Routes
const AddMatterModal = lazy(() => import('./components/main_content/forms/add_matter_model'));
const MatterSuccessModal = lazy(() =>
  import('./components/main_content/forms/matter_success_modal')
);
const AddClaimModal = lazy(() => import('./components/main_content/forms/add_claim_model'));
const ClaimSuccessModal = lazy(() => import('./components/main_content/forms/claim_success_modal'));

// Un Auth Routes
const Login = lazy(() => import('./components/auth/login/login'));
const TwoFa = lazy(() => import('./components/auth/login/2fa'));
const Register = lazy(() => import('./components/auth/register/register'));
const LinkExpire = lazy(() => import('./components/auth/link_expire/link_expire'));
const ResetPassword = lazy(() => import('./components/auth/reset_password/reset_password'));
const NewPassword = lazy(() => import('./components/auth/reset_password/new_password'));
const CheckEmail = lazy(() => import('./components/auth/reset_password/check_email'));

// browser history
const customHistory = createBrowserHistory({ basename: process.env.REACT_APP_PATHOFBUILD });

function App(props) {
  const isLoggedIn = useSelector(state => state?.auth?.isLogin);
  const isStaff = useSelector(state => state?.auth?.user?.is_staff);

  // messenger instance
  const userID = useSelector(state => state?.auth?.user?.id);
  const firstName = useSelector(state => state?.auth?.user?.first_name);
  const lastName = useSelector(state => state?.auth?.user?.last_name);
  const APPID = process.env.REACT_APP_SENDBIRD_APP_ID;

  const dispatchCall = useDispatch();

  const NonLogedInRoutes = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn && isStaff) {
          return (
            <Redirect
              to={{
                pathname: '/bulk-access-contacts',
                state: { from: props.location },
              }}
            />
          );
        }
        if (isLoggedIn && !isStaff) {
          return (
            <Redirect
              to={{
                pathname: '/global-claim',
                state: { from: props.location },
              }}
            />
          );
        } else if (!isLoggedIn && props.location.pathname === '/') {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        } else {
          return (
            <Suspense className="loader" fallback={<LoaderMain></LoaderMain>}>
              <Component {...props} />
            </Suspense>
          );
        }
      }}
    />
  );
  const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        if (isStaff) {
          if (props.location.pathname === '/bulk-access-contacts') {
            return (
              <Suspense className="loader" fallback={<LoaderMain></LoaderMain>}>
                <Component {...props} />
              </Suspense>
            );
          }
        }
        if (!isStaff) {
          if (isLoggedIn) {
            if (props.location.pathname === '/') {
              if (localStorage.getItem('redirect_url')) {
                let redirectUrl = localStorage.getItem('redirect_url');
                localStorage.removeItem('redirect_url');
                return (
                  <Redirect
                    to={{
                      pathname: redirectUrl,
                      state: { from: props.location },
                    }}
                  />
                );
              }
              return (
                <Redirect
                  to={{
                    pathname: '/global-claim',
                    state: { from: props.location },
                  }}
                />
              );
            } else {
              return (
                <>
                  {userID && (
                    <SendBirdProvider
                      appId={APPID}
                      theme={'light'}
                      userId={userID}
                      nickname={firstName + ' ' + lastName}
                      useMessageGrouping={true}
                    >
                      <Component {...props} />
                    </SendBirdProvider>
                  )}
                  {props.location.pathname !== '/' && (
                    <Suspense className="loader" fallback={<LoaderMain></LoaderMain>}>
                      <AddClaimModal {...props} />
                    </Suspense>
                  )}
                  <Suspense className="loader" fallback={<LoaderMain></LoaderMain>}>
                    <MatterSuccessModal {...props} />
                  </Suspense>
                  <Suspense className="loader" fallback={<LoaderMain></LoaderMain>}>
                    <AddMatterModal {...props} />
                  </Suspense>
                  <Suspense className="loader" fallback={<LoaderMain></LoaderMain>}>
                    <ClaimSuccessModal {...props} />
                  </Suspense>
                </>
              );
            }
          } else {
            if (props.location.pathname === '/') {
              return (
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: props.location },
                  }}
                />
              );
            } else {
              if (props?.location?.pathname !== '/login' && !props?.location?.key) {
                localStorage.setItem('redirect_url', props?.location?.pathname);
              }
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: props.location },
                  }}
                />
              );
            }
          }
        }
      }}
    />
  );
  const isInternetOff = useSelector(
    state => state.internetFlag && state.internetFlag?.is_internet_on
  );
  useEffect(() => {
    dispatchCall(
      internetFlag({
        is_internet_on: window?.navigator?.onLine,
      })
    );
  }, []);
  return (
    <ErrorBoundary>
      <div className="App">
        {!isInternetOff ? (
          <InternetErrorPage />
        ) : (
          <Router history={customHistory} basename={process.env.REACT_APP_PATHOFBUILD}>
            <Route
              render={({ location }) => (
                <Switch location={location}>
                  <NonLogedInRoutes
                    isLoggedIn={isLoggedIn}
                    path="/"
                    exact
                    title="Login"
                    component={Login}
                  />
                  <NonLogedInRoutes
                    isLoggedIn={isLoggedIn}
                    path="/2fa-code"
                    exact
                    title="2fa"
                    component={TwoFa}
                  />
                  <NonLogedInRoutes
                    isLoggedIn={isLoggedIn}
                    path="/reset-password"
                    exact
                    title="Reset"
                    component={ResetPassword}
                  />
                  <NonLogedInRoutes
                    isLoggedIn={isLoggedIn}
                    path="/check-email"
                    exact
                    title="CheckEmail"
                    component={CheckEmail}
                  />
                  <NonLogedInRoutes
                    isLoggedIn={isLoggedIn}
                    path="/change-password"
                    exact
                    title="NewPassword"
                    component={NewPassword}
                  />
                  <NonLogedInRoutes
                    isLoggedIn={isLoggedIn}
                    path="/401"
                    exact
                    title="errorMessage"
                    component={ErrorMessage}
                  />
                  <NonLogedInRoutes
                    isLoggedIn={isLoggedIn}
                    path="/login/:token?"
                    exact
                    title="Login"
                    component={Login}
                  />
                  <NonLogedInRoutes
                    isLoggedIn={isLoggedIn}
                    path="/user-registration"
                    exact
                    title="Register"
                    component={Register}
                  />
                  <NonLogedInRoutes
                    isLoggedIn={isLoggedIn}
                    path="/link-expire"
                    exact
                    title="Link Expire"
                    component={LinkExpire}
                  />
                  <RestrictedRoute path="/" component={AppRouter} isLoggedIn={isLoggedIn} />
                </Switch>
              )}
            />
            <Suspense className="loader" fallback={<LoaderMain></LoaderMain>}>
              <StaticPage></StaticPage>
            </Suspense>
          </Router>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default App;
